footer {
    flex-shrink: 0;
    height: 50px;
    text-align: center;
    font-size: 0.8em;
    background: var(--footer-back);
    border-top: var(--main-menu-back) 5px solid;
}

footer span {
    display: block;
    margin-top: 15px;
}

@media (max-width: 768px) {
}