* {
    --form-max-width: 900px;
}

form {
    max-width: var(--form-max-width);
}

fieldset {
    border: var(--lines-color) 1px solid;
    padding: 10px;
}

legend {
    margin: 10px;
    padding: 0 10px;
    color: var(--form-label);
}

form label {
    display: block;
    margin: 30px;
    color: var(--input-label);
}

label:first-of-type {
    margin-top: 10px;
}

input {
    display: block;
    width: calc(100% - 10px * 2);
    height: 25px;
    margin: 3px 0 0 0;
    padding: 5px 10px;
    border: var(--input-border) 1px solid;
    background-color: var(--input-back);
    color: var(--input-text);
}

input[type="submit"] {
    box-sizing: content-box;
    width: calc(100% / 2);
    height: 35px;
    margin: 50px auto;
    border: var(--submit-border) 1px solid;
    background-color: var(--submit-back);
    color: var(--submit-text);
}

input[type="submit"]:hover {
    border: var(--submit-hover-border) 1px solid;
    background-color: var(--submit-hover-back);
    color: var(--submit-hover-text);
    cursor: pointer;
}

input[type="submit"]:active {
    border: var(--submit-border) 1px solid;
    background-color: var(--submit-back);
    color: var(--submit-text);
}

img.loader {
    display: block;
    margin: 30px auto 30px auto;
}

.ideas {
    margin-top: 40px;
    padding: 20px 0 20px 0;
    max-width: var(--form-max-width);
    background-color: var(--panel-background);
}

.ideas h2 {
    margin: 10px;
    font-size: 2em;
    font-weight: normal;
    text-align: center;
}

.ideas p {
    margin: 30px;
    white-space: pre-wrap;
}

.limit {
    margin: 50px;
    padding: 20px;
    max-width: var(--form-max-width);
    color: var(--alert-color);
    background-color: var(--alert-background);
}

.limit p {
    margin: 0 0 20px 0;
    padding: 0;
    text-align: left;
    word-wrap: normal;
}

.limit p:last-of-type {
    margin: 0;
}

.limit a {
    display: inline;
    padding: 0;
}

.limit a:hover {
    background: none;
    text-decoration: underline;
}

@media (max-width: 768px) {
    fieldset {
        padding: 10px 0 0 0;
        border-width: 1px 0 0 0;
    }

    legend {
        padding: 0 5px;
        text-align: center;
    }

    form label {
        margin: 30px 0 30px 0;
    }

    input {
        height: 35px;
    }

    input[type="submit"] {
        width: calc(100% / 1.5);
        height: 50px;
    }

    .ideas h2 {
        font-size: 1.5em;
    }

    .ideas p {
        margin: 20px;
    }

    .limit {
        margin: 0;
        padding: 20px;
    }

    .limit p {
        margin: 0;
    }
}
