button {
    border: var(--invisible-btn-border) 1px solid;
    background-color: var(--invisible-btn-back);
    color: var(--invisible-btn-text);
    padding: 10px 50px;
    cursor: pointer;
}

button:hover {
    color: var(--invisible-btn-hover-text);
    border-color: var(--invisible-btn-hover-border);
    background-color: var(--invisible-btn-hover-back);
}

button:active {
    color: var(--invisible-btn-text);
    border-color: var(--invisible-btn-border);
    background-color: var(--invisible-btn-back);
}
