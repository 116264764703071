@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;700&display=swap');

html, body, #root {
    height: 100%;
    margin: 0;
}

body {
    font-family: 'Montserrat', sans-serif;
    font-size: 14pt;
    background: var(--back-color);
    color: var(--text-color);
}

#root {
    display: flex;
    flex-direction: column;
}