header {
    background: var(--header-back);
    border-top: var(--main-menu-back) 5px solid;
}

.logo, .logo-small {
    display: block;
    margin: auto;
}

.logo-small {
    display: none;
}

@media (max-width: 768px) {
    header {
        padding-top: 10px;
    }

    .logo-small {
        display: block;
    }

    .logo {
        display: none;
    }
}