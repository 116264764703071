ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0;
    padding: 0;
    background: var(--main-menu-back);
}

li {
    list-style: none;
}

a {
    display: block;
    width: 200px;
    padding: 8px;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
    color: var(--main-menu-links);
}

a:hover {
    background: var(--main-menu-hover);
}

.current {
    background: var(--main-menu-hover);
    cursor: default;
}

#menu_toggle,
.menu_btn {
    display: none;
}


@media (max-width: 768px) {
    ul {
        padding: 16px 0;
    }

    a {
        width: 100%;
        padding: 12px 0;
    }

    .menu_btn {
        display: block;
        padding: 7px 14px;
        text-align: right;
        font-weight: bold;
        color: var(--main-menu-links);
        background: var(--main-menu-back);
    }

    .menu_btn_closed {
        display: none;
    }

    .menu {
        display: none;
        text-align: center;
   }

    #menu_toggle:checked ~ .menu {
        display: block;
    }

    #menu_toggle:checked~.menu_btn .menu_btn_opened {
        display: none;
    }

    #menu_toggle:checked ~ .menu_btn .menu_btn_closed {
        display: block;
    }
}