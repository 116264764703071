* {
    --block-max-width: 900px;
}

article {
    max-width: 1600px;
    min-height: 700px;
    margin: auto;
    padding: 20px;

    background-image: url('./../../style/back.jpeg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
}

h1 {
    margin: 100px 0 0 0;
    font-size: 3em;
    font-weight: lighter;
}

strong {
    font-weight: bold;
}

hr {
    max-width: 1000px;
    margin: 0 0 30px 0;
    border-width: 1px 0 0 0;
    border-color: var(--lines-color);
}

p {
    max-width: var(--block-max-width);
    margin: 0 0 20px 0;
    line-height: 1.5em;
}

article div {
    max-width: var(--block-max-width);
    text-align: right;
}


@media (max-width: 768px) {
    article {
        background-image: url('./../../style/back-small.jpeg');
        background-size: auto;
        background-position: top center;
    }

    h1 {
        margin: 0 0 10px 0;
        font-size: 1.5em;
        text-align: center;
    }

    article div {
        text-align: center;
    }
}
