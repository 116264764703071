:root {
    --back-color: #000;
    --text-color: #fff;

    --links-color: #fff;
    --links-hover: #fff;

    --header-back: #1b1b1b;
    --main-menu-back: #ffb601;
    --main-menu-links: #000;
    --main-menu-hover: #c97900;

    --footer-back: var(--header-back);

    --lines-color: #00577d;

    --invisible-btn-text: rgba(200, 200, 250, .9);
    --invisible-btn-border: rgba(200, 200, 250, .9);
    --invisible-btn-back: transparent;
    --invisible-btn-hover-text: rgba(200, 200, 250, 1);
    --invisible-btn-hover-color: rgba(220, 220, 255, 1);
    --invisible-btn-hover-back: rgba(240, 240, 255, 0.1);

    --form-label: rgba(150, 150, 255, 0.8);

    --input-label: rgba(255, 255, 255, 0.9);
    --input-border: rgba(255, 255, 255, 0.9);
    --input-back: rgba(255, 255, 255, 0.1);
    --input-text: #fff;

    --submit-border: rgba(200, 200, 255, 0.9);
    --submit-text: rgba(200, 200, 255, 1);
    --submit-back: rgba(200, 200, 255, 0.2);
    --submit-hover-border: rgba(200, 200, 255, 0.9);
    --submit-hover-text: rgba(255, 255, 255, 1);
    --submit-hover-back: rgba(200, 200, 255, 0.3);

    --panel-background: rgba(200, 200, 255, 0.2);

    --alert-color: #004;
    --alert-background: var(--main-menu-back);
}
